<!--  -->
<template>
  <div class="datadetail" v-loading="loading">
    <!-- 头部 -->
    <div class="datadetail-header">
      <div class="datadetail-header-title" v-if="this.$route.query.type === 'new'">{{ title }}</div>
      <div
        class="datadetail-header-title"
        v-if="this.$route.query.type === 'preview' && this.$route.query.source === 'spu'"
      >
        {{ formData.product_name }}
      </div>
      <div
        class="datadetail-header-title"
        v-if="this.$route.query.type === 'edit' && this.$route.query.source === 'spu'"
      >
        编辑{{ formData.product_name }}
      </div>
      <div
        class="datadetail-header-title"
        v-if="this.$route.query.type === 'preview' && this.$route.query.source === 'sku'"
      >
        {{ formData.sku_name }}
      </div>
      <div
        class="datadetail-header-title"
        v-if="this.$route.query.type === 'edit' && this.$route.query.source === 'sku'"
      >
        编辑{{ formData.sku_name }}
      </div>

      <div class="operate-btns">
        <el-button
          class="edit-btn"
          v-if="this.$route.query.type === 'preview' && role !== 3"
          @click="goEdit"
        >
          编辑
        </el-button>
        <el-button
          class="del-btn"
          v-if="this.$route.query.type === 'preview' && role !== 3"
          @click="deleteData($route.query.id)"
        >
          删除
        </el-button>
      </div>
    </div>

    <!-- 新建模式 -->
    <el-form
      label-position="left"
      label-width="145px"
      v-if="this.$route.query.type === 'new'"
      :model="formData"
      :rules="rules"
      ref="form"
    >
      <div v-for="(block, indexb) in config" :key="`block${indexb}`">
        <div
          class="datadetail-block"
          v-if="block.title !== '系统字段' && block.title !== '清洗备注'"
        >
          <div class="datadetail-block-title">{{ block.title }}</div>
          <template v-for="(item, indexi) in block.itemList">
            <div
              :class="
                item.editType === 'textarea'
                  ? 'datadetail-block-item-textarea'
                  : 'datadetail-block-item'
              "
              :key="`${block.title}item${indexi}`"
            >
              <el-form-item
                :label="item.name"
                class="datadetail-item-title"
                :required="item.required"
              ></el-form-item>
              <el-form-item
                v-if="item.editType === 'input' || (item.editType === '' && item.extra !== 'map')"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-input
                  :disabled="item.disable"
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item
                v-if="item.editType === 'number'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-input-number
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  controls-position="right"
                  :min="0"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                v-if="item.editType === 'select'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-select
                  clearable
                  filterable
                  :disabled="item.disable"
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(opitem, index) in options[item.property]"
                    :key="index"
                    :label="opitem.label"
                    :value="opitem.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="item.editType === 'lenovo'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-select
                  clearable
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  v-if="item.property === 'product_name'"
                  filterable
                  remote
                  :remote-method="productCodeRemoteMethod"
                  :loading="remoteLoading"
                  placeholder="请输入关键词"
                >
                  <el-option
                    v-for="(val, index) in options[item.property]"
                    :key="index"
                    :label="val.label"
                    :value="val.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </template>
        </div>
      </div>
    </el-form>

    <!-- 预览模式 -->
    <el-form v-if="this.$route.query.type === 'preview' && !loading">
      <div v-for="(block, indexb) in config" :key="`block${indexb}`">
        <div class="datadetail-block">
          <div class="datadetail-block-title">{{ block.title }}</div>
          <!-- 附带hover后显示编辑入口的组件 完成编辑时会触发submit -->
          <template v-for="(item, indexi) in block.itemList">
            <!-- 机构详情 -->
            <div :key="`${block.title}item${indexi}`" class="datadetail-block-item-detail">
              <eyaoDetailInput
                :edit-valid="item.edit"
                :edit-type="item.editType"
                :show-type="item.showType"
                :title="item.name"
                :init-value="formData[item.property]"
                :property="item.property"
                :required="item.required"
                :options="options"
                @submit="submit"
                @handleSuperiorOrg="handleSuperiorOrg"
                :auths="`${block.show}`"
              />
            </div>
          </template>
        </div>
      </div>

      <div class="related-sku" v-if="this.$route.query.source === 'spu'" v-loading="skuLoading">
        <p class="title">相关SKU</p>
        <div class="datalist-title-action">
          <div class="action-left">
            <el-button
              v-auto-unfocus
              type="primary"
              class="data-list-createBtn"
              @click="handleAddData"
            >
              新建数据
            </el-button>
          </div>

          <div class="action-right">
            <el-button icon="el-icon-refresh-right" @click="getRelatedSku"></el-button>
          </div>
        </div>
        <div v-if="filterList.length > 0" class="datalist-title-filter">
          <div v-for="(item, index) in filterList" :key="index" class="datalist-title-filter-item">
            {{ getLabel(item.name) }}: {{ item.label }}
            <i class="el-icon-close" @click="deleteFilterItem(item.name)"></i>
          </div>
          <el-button
            v-if="filterList.length"
            class="datalist-title-button"
            type="text"
            @click="deleteAllFilter"
          >
            重置
          </el-button>
        </div>
        <div class="table-data">
          <eyao-table
            ref="table"
            :table-data="skuTableData"
            :total-num="total"
            :select-page-size="10"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <!-- <el-table-column type="selection" :reserve-selection="true" /> -->
            <el-table-column
              v-for="column in skuRelatedConfigList"
              :key="`${$route.query.source}-${column.property}`"
              :prop="column.property"
              :column-key="column.property"
              :min-width="column.width"
              :label="column.title"
              :sortable="column.sort"
              :fixed="column.fixed"
            >
              <template slot="header" slot-scope="scope">
                <eyao-table-search-header
                  v-if="column.search === 'input' || column.search === 'select'"
                  :form-type="column.search"
                  :property="column.property"
                  :title="column.title"
                  :scope="scope"
                  :options="valueOpts[column.property]"
                  @search="search"
                ></eyao-table-search-header>
                <template v-else>{{ column.title }}</template>
              </template>

              <template slot-scope="scope">
                <!-- id字段标蓝 点击后跳转 -->
                <template
                  v-if="column.property === 'product_name' || column.property === 'sku_name'"
                >
                  <div
                    @click="gotoDetail(scope.row.id)"
                    :style="{ color: '#0069FA', cursor: 'pointer' }"
                  >
                    {{ scope.row[column.property] }}
                  </div>
                </template>
                <!-- 涉及时间字段，format值 -->
                <template v-else-if="column.format">
                  <template>{{ scope.row[column.property] | getFormatTime }}</template>
                </template>
                <template v-else>{{ scope.row[column.property] }}</template>
              </template>
            </el-table-column>

            <!-- 操作列 -->
            <el-table-column v-if="role !== 3" min-width="130" fixed="right" label="操作">
              <div slot-scope="scope" class="data-list-option">
                <div @click="gotoEdit(scope.row)">编辑</div>
                <div @click="deleteSkuData(scope.row.id)">删除</div>
              </div>
            </el-table-column>

            <template v-slot:empty>
              <div class="no_data" v-if="!skuLoading && !skuTableData.length">
                <img
                  src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                  alt="未查询到相关数据"
                />
                未查询到相关数据
              </div>
            </template>
          </eyao-table>
        </div>
      </div>
    </el-form>

    <!-- 编辑模式 -->
    <el-form
      label-position="right"
      label-width="84px"
      v-if="this.$route.query.type === 'edit' && JSON.stringify(formData) != '{}'"
      :model="formData"
      :rules="rules"
      ref="form"
    >
      <div v-for="(block, indexb) in config" :key="`block${indexb}`">
        <div class="datadetail-block" v-if="block.show !== 'no'">
          <div class="datadetail-block-title">{{ block.title }}</div>
          <template v-for="(item, indexi) in block.itemList">
            <div
              :class="
                item.editType === 'textarea'
                  ? 'datadetail-block-item-textarea'
                  : 'datadetail-block-item'
              "
              :key="`${block.title}item${indexi}`"
            >
              <el-form-item
                :label="item.name"
                class="datadetail-item-title"
                :required="item.required"
              ></el-form-item>
              <el-form-item
                v-if="item.editType === 'input' || (item.editType === '' && item.extra !== 'map')"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-input
                  v-if="item.property === 'doubt_reason'"
                  :disabled="item.disable || block.show === 'read' || formData.in_doubt === '2'"
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
                <el-input
                  v-else
                  :disabled="item.disable || block.show === 'read'"
                  class="datadetail-item-input"
                  v-model.trim="formData[item.property]"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <el-form-item
                v-if="item.editType === 'number'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-input-number
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  controls-position="right"
                  :min="0"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                v-if="item.editType === 'select'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-select
                  clearable
                  filterable
                  :disabled="item.disable"
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(opitem, index) in options[item.property]"
                    :key="index"
                    :label="opitem.label"
                    :value="opitem.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-if="item.editType === 'lenovo'"
                class="datadetail-form-item"
                :prop="item.property"
              >
                <el-select
                  clearable
                  class="datadetail-item-input"
                  v-model="formData[item.property]"
                  v-if="item.property === 'product_name'"
                  filterable
                  remote
                  :remote-method="productCodeRemoteMethod"
                  :loading="remoteLoading"
                  placeholder="请输入关键词"
                >
                  <el-option
                    v-for="(val, index) in options[item.property]"
                    :key="index"
                    :label="val.label"
                    :value="val.label"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </template>
        </div>
      </div>
    </el-form>

    <!-- 底部保存按钮栏 -->
    <div v-if="this.$route.query.type !== 'preview' && !loading" class="datadetail-end">
      <el-button class="datadetail-end-button1" type="primary" @click="handleSave">保存</el-button>
      <el-button class="datadetail-end-button2" @click="handleBack">取消</el-button>
    </div>

    <!-- 删除弹窗 -->
    <el-dialog
      title="确认删除吗"
      :visible.sync="showDeleteDialog"
      width="400px"
      class="deleteDataModel"
    >
      <span slot="title" :style="{ fontSize: '14px', color: '#1C2028' }">
        <i class="el-icon-warning" :style="{ fontSize: '18px', color: '#FA9600' }" />
        确认删除吗
      </span>
      请填写删除原因
      <el-input v-model="deleteReason"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-cancelBtn" @click="showDeleteDialog = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deleteConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- spu关联列表删除弹窗 -->
    <el-dialog
      title="确认删除吗"
      :visible.sync="showSkuDeleteDialog"
      width="400px"
      class="deleteDataModel"
    >
      <span slot="title" :style="{ fontSize: '14px', color: '#1C2028' }">
        <i class="el-icon-warning" :style="{ fontSize: '18px', color: '#FA9600' }" />
        确认删除吗
      </span>
      请填写删除原因
      <el-input v-model="skuDeleteReason"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button class="dialog-footer-cancelBtn" @click="showSkuDeleteDialog = false">
          取 消
        </el-button>
        <el-button type="primary" @click="deleteSkuConfirm">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 重复数据弹窗提示 -->
    <el-dialog
      :visible.sync="repeatVisible"
      width="400px"
      :before-close="handleCancel"
      :show-close="false"
    >
      <div class="dialog-tit">
        <i class="el-icon-warning"></i>
        <p>数据重复</p>
      </div>
      <div class="data-name">提交信息与以下数据存在编码重复</div>
      <div class="data-name-content" v-if="this.$route.query.source === 'spu'">
        <span
          class="content-link"
          type="text"
          @click="handleGoRepeat(item)"
          v-for="(item, index) in repeatData"
          :key="index"
        >
          {{ item.product_name }}
        </span>
      </div>
      <div class="data-name-content" v-if="this.$route.query.source === 'sku'">
        <span
          class="content-link"
          type="text"
          @click="handleGoRepeat(item)"
          v-for="(item, index) in repeatData"
          :key="index"
        >
          {{ item.sku_name }}
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleCancel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetDictOptionList,
  CreateMainDataDetail,
  SearchMainData,
  GetMainDataDetail,
  EditMainDataDetail,
  GetFieldPermissions,
  DeleteMainDataDetail,
} from '@/api/data';
import _ from 'lodash';
import spuConfig from './config/drugSpu';
import skuConfig from './config/drugSku';
import skuRelatedConfig from '../drug-list/config/drug-sku';
import { REQUEST_TITLE_TYPE, REQUEST_TYPE } from '@/utils/constant';
import eyaoDetailInput from '@/components/detail-input';
import { dateFormat } from '@/utils/util';
import eyaoTable from '@/components/table';
import eyaoTableSearchHeader from '@/components/table-search-header';
import skuLabelConfig from '../drug-list/labelConfig/sku';
export default {
  components: {
    eyaoDetailInput,
    eyaoTable,
    eyaoTableSearchHeader,
  },
  filters: {
    getFormatTime(value) {
      if (value && !value.startsWith('0')) {
        return dateFormat(new Date(value), 'YYYY-MM-dd HH:mm:SS');
      }
      return '';
    },
  },
  data() {
    return {
      repeatData: [],
      repeatVisible: false,
      delectId: '',
      configObj: {},
      filterList: [],
      propertyName: [
        'therapeutic_area_name',
        'productline_name',
        'category',
        'is_RX',
        'source',
        'in_doubt',
        'administration',
        'prepared_form',
        'unit',
        'sales_status',
        'is_medical_insurance',
      ], // 需要转换成label展示的字段
      labelConfig: [],
      skuLoading: false,
      skuTableData: [],
      total: 0,
      page: 1,
      pageSize: 10,
      skuRelatedConfigList: [],
      deleteReason: '',
      skuDeleteReason: '',
      showSkuDeleteDialog: false,
      showDeleteDialog: false,
      delectSkuId: '',
      loading: false,
      formData: {},
      initial: {},
      rules: {},
      config: [],
      valueOpts: {},
      options: {
        therapeutic_area_name: [],
        productline_name: [],
        category: [],
        is_RX: [],
        source: [],
        in_doubt: [],
        is_deleted: [],

        administration: [],
        prepared_form: [],
        unit: [],
        sales_status: [],
        is_medical_insurance: [],
        // product_code: [],
        product_name: [],
      },
      remoteLoading: false,
      creatData: [], // 存放新建数据
      detailEdit: [], // 存放详情编辑数据
      editData: [], // 存放编辑数据
    };
  },
  computed: {
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }

      return newRole;
    },
    title() {
      return `新建${REQUEST_TITLE_TYPE[this.$route.query.source]}`;
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'drug-detail') {
        this.init();
      }
    },
    'formData.therapeutic_area_name': {
      handler(newval) {
        this.formData.therapeutic_area_code = newval;
      },
      deep: true,
    },
    'formData.productline_name': {
      handler(newval) {
        this.formData.productline_code = newval;
      },
      deep: true,
    },
  },
  async created() {
    // 获取剂型分类
    GetDictOptionList({ dict_api_name: 'administration', dict_type: '6' })
      .then(res => {
        this.options.administration = res.dict_list;
      })
      .catch(err => {
        this.$message.error(err.message);
      });

    // 获取剂型
    GetDictOptionList({ dict_api_name: 'prepared_form', dict_type: '6' })
      .then(res => {
        this.options.prepared_form = res.dict_list;
      })
      .catch(err => {
        this.$message.error(err.message);
      });
    // 获取单位
    GetDictOptionList({ dict_api_name: 'unit', dict_type: '6' })
      .then(res => {
        this.options.unit = res.dict_list;
      })
      .catch(err => {
        this.$message.error(err.message);
      });
    // 获取销售状态
    GetDictOptionList({ dict_api_name: 'sales_status', dict_type: '6' })
      .then(res => {
        this.options.sales_status = res.dict_list;
      })
      .catch(err => {
        this.$message.error(err.message);
      });
    // 获取是否医保
    GetDictOptionList({ dict_api_name: 'is_medical_insurance', dict_type: '6' })
      .then(res => {
        this.options.is_medical_insurance = res.dict_list;
      })
      .catch(err => {
        this.$message.error(err.message);
      });
    if (this.$route.query.source === 'spu') {
      this.config = spuConfig;
      skuRelatedConfig.forEach(item => {
        if (item.default) {
          this.skuRelatedConfigList.push(item);
        }
        this.configObj[item.property] = item;
      });
      this.labelConfig = skuLabelConfig;
      this.getOptions();
      await this.getAuthority('t_product');
      // 获取所属产品领域名称
      GetDictOptionList({ dict_api_name: 'therapeutic_area_code', dict_type: '5' })
        .then(res => {
          console.log(res.dict_list);
          this.options.therapeutic_area_name = res.dict_list;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
      // 获取产品线/产品类型名称
      GetDictOptionList({ dict_api_name: 'productline_code', dict_type: '5' })
        .then(res => {
          this.options.productline_name = res.dict_list;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
      // 获取药品类型
      GetDictOptionList({ dict_api_name: 'category', dict_type: '5' })
        .then(res => {
          this.options.category = res.dict_list;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
      // 获取是否处方药
      GetDictOptionList({ dict_api_name: 'is_RX', dict_type: '5' })
        .then(res => {
          this.options.is_RX = res.dict_list;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    }
    if (this.$route.query.source === 'sku') {
      this.config = skuConfig;
      await this.getAuthority('t_product_sku');
    }
    // 是否存疑
    GetDictOptionList({ dict_api_name: 'in_doubt', dict_type: '0' })
      .then(res => {
        this.options.in_doubt = res.dict_list;
      })
      .catch(err => {
        this.$message.error(err.message);
      });
    // 数据来源
    GetDictOptionList({ dict_api_name: 'source', dict_type: '4' })
      .then(res => {
        console.log(res.dict_list);
        this.options.source = res.dict_list;
      })
      .catch(err => {
        this.$message.error(err.message);
      });
  },
  mounted() {
    this.init();
  },
  methods: {
    // 获取下拉选项数据
    getOptions() {
      const optionsApi = [];
      const opts = {};
      skuRelatedConfig.forEach(item => {
        if (item.search === 'select') {
          let params = {
            dict_api_name: item.property,
            dict_type: '6',
          };
          if (item.property === 'source') {
            params = {
              dict_api_name: 'source',
              dict_type: '4',
            };
          }
          if (item.property === 'in_doubt') {
            params = {
              dict_api_name: 'in_doubt',
              dict_type: '0',
            };
          }

          optionsApi.push(
            GetDictOptionList(params).then(res => {
              opts[item.property] = res.dict_list.map(opt => {
                return {
                  text: opt.label,
                  label: opt.label,
                  value: opt.code,
                };
              });
              return true;
            }),
          );
        }
      });
      Promise.all(optionsApi).then(() => {
        Object.keys(opts).forEach(key => {
          this.$set(this.valueOpts, key, opts[key]);
        });
        console.log(this.valueOpts);
      });
    },
    handleGoRepeat(val) {
      const href = this.$router.resolve({
        path: '/dashboard/drug-detail',
        query: {
          id: val.id,
          type: 'preview',
          source: this.$route.query.source,
        },
      });
      window.open(href.href, '_blank');
    },
    handleCancel() {
      this.repeatVisible = false;
      this.repeatData = [];
    },
    handleAddData() {
      const routerData = this.$router.resolve({
        path: '/dashboard/drug-detail',
        query: { type: 'new', source: 'sku' },
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },
    // 跳转详情
    gotoDetail(id) {
      const routerData = this.$router.resolve({
        path: '/dashboard/drug-detail',
        query: { type: 'preview', source: 'sku', id },
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },
    deleteAllFilter() {
      localStorage.removeItem('drug-filter-detail');
      this.filterList = [];
      this.page = 1;
      this.pageSize = 10;
      this.total = 0;
      this.$refs.table.clearFilter();
      setTimeout(() => {
        this.getRelatedSku();
      });
    },
    deleteFilterItem(key) {
      this.filterList = this.filterList.filter(item => item.name !== key);
      this.setStorage();
      this.$refs.table.clearFilter([key]);
      this.page = 1;
      this.pageSize = 10;
      this.total = 0;
      this.getRelatedSku();
    },
    getLabel(property) {
      return this.configObj[property].title;
    },
    gotoEdit(row) {
      const routerData = this.$router.resolve({
        path: '/dashboard/drug-detail',
        query: { type: 'edit', source: 'sku', id: row.id },
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },
    search(property, val, formType, label) {
      console.log(property, val, formType, label);
      if (!this.filterList.length) {
        if (formType === 'select') {
          this.filterList.push({
            name: property,
            value: val,
            label,
          });
        } else {
          this.filterList.push({
            name: property,
            value: val,
            label: val,
          });
        }
      } else {
        if (this.filterList.every(item => item.name !== property)) {
          if (formType === 'select') {
            this.filterList.push({
              name: property,
              value: val,
              label,
            });
          } else {
            this.filterList.push({
              name: property,
              value: val,
              label: val,
            });
          }
        } else {
          this.filterList.forEach(item => {
            if (item.name === property) {
              if (formType === 'select') {
                item.value = val;
                item.label = label;
              } else {
                item.value = val;
              }
            }
          });
        }
      }
      this.setStorage();
      this.page = 1;
      this.pageSize = 10;
      this.total = 0;
      this.getRelatedSku();
    },
    setStorage() {
      const filterData = this.getStorage();
      filterData[this.$route.query.source] = this.filterList;
      localStorage.setItem('drug-filter-detail', JSON.stringify(filterData));
    },
    getStorage() {
      const str = localStorage.getItem('drug-filter-detail');
      return str ? JSON.parse(str) : {};
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getRelatedSku();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getRelatedSku();
    },
    // 处理列表中下拉菜单的label值
    dealDataLabel(list) {
      const reg = /^[0-9a-zA-Z]/;
      list.forEach(ele => {
        Object.keys(ele).forEach(key => {
          if (this.propertyName.includes(key)) {
            // console.log(key, ele[key]);
            // 向下拉取数据，会导致之前转化为label的数据消失，是因为转了文字后，这里不能识别。
            // 解决方法：判断是否是数字或者字母开头，纯文字不管（已经转为label）
            if (reg.test(ele[key])) {
              ele[key] = this.getCodeLabel(key, ele[key]);
            }
          }
        });
      });
    },
    getCodeLabel(key, value) {
      //   console.log(key, value);
      const valObj = this.labelConfig.filter(item => item.property === key);
      //   console.log(valObj);
      let valArr = [];
      if (JSON.stringify(valObj) !== '{}') {
        valArr = valObj[0].value;
      }
      let res = '';
      if (valArr.length > 0) {
        const arr = valArr.filter(item => item.code === value);
        if (arr.length > 0) {
          res = arr[0].label;
        }
      }
      return res;
    },
    getRelatedSku() {
      this.skuLoading = true;
      this.filterList = this.getStorage()[this.$route.query.source] || [];
      const arr = this.skuRelatedConfigList.map(item => item.property);
      arr.push('id');
      let req = {};
      if (this.filterList.length) {
        const params = [];
        this.filterList.forEach(item => {
          params.push({
            field_name: item.name,
            type: 1,
            keywords: [item.value],
          });
        });
        params.push({
          field_name: 'product_code',
          type: 2,
          keywords: [this.formData.product_code],
        });
        req = {
          main_data: 't_product_sku',
          fields: arr,
          conditions: params,
          index: this.page,
          page_size: this.pageSize,
        };
      } else {
        req = {
          main_data: 't_product_sku',
          fields: arr,
          conditions: [
            {
              field_name: 'product_code',
              type: 2,
              keywords: [this.formData.product_code],
            },
          ],
          index: this.page,
          page_size: this.pageSize,
        };
      }

      SearchMainData(req)
        .then(res => {
          const dep = res.data.map(item => JSON.parse(item));
          this.dealDataLabel(dep);
          this.skuTableData = dep;
          console.log('相关sku', this.skuTableData);
          this.total = res.total_num;
          this.skuLoading = false;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    deleteSkuConfirm() {
      if (this.skuDeleteReason === '') {
        this.$message.error('请填写删除原因');
        return false;
      }
      const params = {
        obj_name: 't_product_sku',
        data_id: this.delectSkuId,
        reason: this.skuDeleteReason,
      };
      DeleteMainDataDetail(params)
        .then(() => {
          this.$message.success('删除成功');
          this.showSkuDeleteDialog = false;
          this.skuDeleteReason = '';
          this.getRelatedSku();
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    deleteConfirm() {
      if (this.deleteReason === '') {
        this.$message.error('请填写删除原因');
        return false;
      }
      const params = {
        obj_name: REQUEST_TYPE[this.$route.query.source],
        data_id: this.delectId,
        reason: this.deleteReason,
      };
      DeleteMainDataDetail(params)
        .then(() => {
          this.$message.success('删除成功');
          this.showDeleteDialog = false;
          this.deleteReason = '';
          this.handleBack();
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    deleteSkuData(id) {
      this.delectSkuId = id;
      this.showSkuDeleteDialog = true;
    },
    deleteData(id) {
      console.log(id);
      this.delectId = id;
      this.showDeleteDialog = true;
    },
    // 编辑
    goEdit() {
      this.$route.query.type = 'edit';
      this.$route.query.form = 'detail';
      this.init();
    },
    // 获取字段权限
    async getAuthority(name) {
      const readWrite = await GetFieldPermissions({ index_name: name, operation: 'read/write' });
      const arr = [];
      if (readWrite.field_array.length) {
        readWrite.field_array.forEach(item => {
          if (item.field_set_name) {
            arr.push(item.field_set_name);
          }
        });
        this.readWrites = [...new Set(arr)];
        console.log('去重后字段组名', this.readWrites);
      }
      const write = await GetFieldPermissions({ index_name: name, operation: 'read' });
      const arr2 = [];
      if (write.field_array.length) {
        write.field_array.forEach(item => {
          if (item.field_set_name) {
            arr2.push(item.field_set_name);
          }
        });
        this.read = [...new Set(arr2)];
        console.log('去重后只读字段组名', this.read);
      }

      if (this.readWrites.length) {
        this.config.forEach(item => {
          if (this.readWrites.includes(item.title)) {
            item.show = 'read/write';
          }
        });
      }
      if (this.read.length) {
        this.config.forEach(item => {
          if (this.read.includes(item.title)) {
            item.show = 'read';
          }
        });
      }
      this.config.forEach(item => {
        if (![...this.readWrites, ...this.read].includes(item.title)) {
          item.show = 'no';
        }
        if (item.title === '证照信息') {
          item.show = 'read/write';
        }
      });
      console.log('最终所有权限', this.config);
    },
    submit(property, val) {
      console.log(property, val);
      if (JSON.stringify(val.new) === JSON.stringify(val.old)) {
        this.$message.error('您未修改任何数据');
        return false;
      }
      if (property === 'sales_status' && val.new === '') {
        this.$message.error('该字段不允许为空');
        return false;
      }
      this.detailEdit.push({
        field_name: property,
        before_val: val.old,
        after_val: val.new,
      });
      const req = {
        obj_name: this.$route.query.source === 'spu' ? 't_product' : 't_product_sku',
        data_id: this.$route.query.id,
        content:
          this.detailEdit.length > 0
            ? this.detailEdit
            : [{ field_name: property, before_val: val.old, after_val: val.new }],
        data_confirm: 0,
      };
      EditMainDataDetail(req)
        .then(res => {
          if (res.code === '0') {
            this.$message.success('编辑成功');
            this.detailEdit = [];
            this.init();
          }
          if (res.code === '1') {
            res.conflict_list.slice(0, 10).forEach(item => {
              this.repeatData.push(JSON.parse(item));
            });
            this.repeatVisible = true;
            this.detailEdit = [];
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    handleSuperiorOrg(val) {
      console.log(val);
      const req = {
        fields: ['id'],
        main_data: 't_product',
        conditions: [
          {
            field_name: 'product_code',
            type: 2,
            keywords: [this.formData.product_code],
          },
        ],
      };
      SearchMainData(req)
        .then(res => {
          console.log(res);
          const id = res.data.map(item => JSON.parse(item));
          console.log(id);
          const href = this.$router.resolve({
            path: '/dashboard/drug-detail',
            query: {
              id: id[0].id,
              name: val,
              type: 'preview',
              source: 'spu',
            },
          });
          window.open(href.href, '_blank');
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    init() {
      const { type, id, source, editDCR } = this.$route.query;
      if (type !== 'new' && !editDCR) {
        if (type === undefined || id === undefined || source === undefined) {
          this.$message.error('未指定数据详情');
          this.$router.push('home');
        }
      }

      if (this.$route.query.type === 'new') {
        this.config.forEach(block => {
          if (block.title !== '系统字段' && block.title !== '清洗备注') {
            block.itemList.forEach(item => {
              // 新建模式下默认值设为空

              this.$set(this.formData, item.property, '');

              // 配置校验规则
              if (item.required) {
                this.rules[item.property] = [
                  { required: true, message: `${item.name}不可为空`, trigger: 'blur' },
                ];
              }
            });
          }
        });
      }

      if (this.$route.query.type === 'preview') {
        this.loading = true;
        const arr = [];
        this.config.forEach(block => {
          block.itemList.forEach(item => {
            arr.push(item.property);
          });
        });
        if (this.$route.query.source === 'sku') {
          arr.push('product_code');
        }
        const req = {
          obj_name: this.$route.query.source === 'spu' ? 't_product' : 't_product_sku',
          data_id: this.$route.query.id,
          fields: arr,
        };
        GetMainDataDetail(req)
          .then(res => {
            const data = JSON.parse(res.data_detail);
            console.log(data);
            data.create_time = dateFormat(new Date(data.create_time), 'YYYY-MM-dd HH:mm:SS');
            data.update_time = dateFormat(new Date(data.update_time), 'YYYY-MM-dd HH:mm:SS');
            data.in_doubt = String(data.in_doubt);
            this.formData = JSON.parse(JSON.stringify(data));
            this.initial = JSON.parse(JSON.stringify(data));
            this.getRelatedSku();
            this.loading = false;
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      }

      if (this.$route.query.type === 'edit') {
        this.loading = true;
        const arr = [];
        this.config.forEach(block => {
          block.itemList.forEach(item => {
            arr.push(item.property);
            // 配置进入时校验规则
            if (item.required) {
              this.rules[item.property] = [
                { required: true, message: `${item.name}不可为空`, trigger: 'blur' },
              ];
            }
          });
        });
        if (this.$route.query.source === 'sku') {
          arr.push('product_code');
        }
        const req = {
          obj_name: this.$route.query.source === 'spu' ? 't_product' : 't_product_sku',
          data_id: this.$route.query.id,
          fields: arr,
        };
        GetMainDataDetail(req)
          .then(res => {
            const data = JSON.parse(res.data_detail);
            console.log(data);
            data.create_time = dateFormat(new Date(data.create_time), 'YYYY-MM-dd HH:mm:SS');
            data.update_time = dateFormat(new Date(data.update_time), 'YYYY-MM-dd HH:mm:SS');
            data.in_doubt = String(data.in_doubt);
            this.formData = JSON.parse(JSON.stringify(data));
            this.initial = JSON.parse(JSON.stringify(data));
            this.loading = false;
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      }
    },

    formatCode(val, options = []) {
      for (const item of options) {
        if (item.label === val) {
          return item.code;
        }
      }
      return '无';
    },
    handleSave: _.throttle(
      function() {
        this.$refs.form.validate(valid => {
          if (valid) {
            if (this.$route.query.type === 'new') {
              for (const [key, value] of Object.entries(this.formData)) {
                if (key !== 'cost_price' && key !== 'sales_price') {
                  this.creatData.push({
                    field_name: `${key}`,
                    before_val: '',
                    after_val: value,
                  });
                }
              }
              if (this.$route.query.source === 'sku') {
                this.creatData.push({
                  field_name: 'product_code',
                  before_val: '',
                  after_val: this.formatCode(this.formData.product_name, this.options.product_name),
                });

                this.creatData.push({
                  field_name: 'cost_price',
                  before_val: '',
                  after_val: String(this.formData.cost_price),
                });
                this.creatData.push({
                  field_name: 'sales_price',
                  before_val: '',
                  after_val: String(this.formData.sales_price),
                });
              }
              // 给source默认赋值
              this.creatData.push({
                field_name: 'source',
                before_val: '',
                after_val: '8',
              });
              const req = {
                obj_name: this.$route.query.source === 'spu' ? 't_product' : 't_product_sku',
                content: this.creatData.filter(item => item.after_val !== ''),
                alter_time: +new Date(),
                data_confirm: 0,
              };
              console.log(req.content);
              CreateMainDataDetail(req)
                .then(res => {
                  if (res.code === '0') {
                    this.$message.success('新建成功');
                    this.handleBack();
                  }
                  if (res.code === '1') {
                    res.conflict_list.slice(0, 10).forEach(item => {
                      this.repeatData.push(JSON.parse(item));
                    });
                    this.repeatVisible = true;
                    this.creatData = [];
                  }
                })
                .catch(err => {
                  this.$message.error(err.message);
                  this.creatData = [];
                });
            }

            if (this.$route.query.type === 'edit') {
              if (this.formData.in_doubt === '1') {
                if (this.formData.doubt_reason === '' || this.formData.doubt_reason == null) {
                  this.$message.error('请填写存疑原因');
                  return;
                }
              }
              this.initial.cost_price = Number(this.initial.cost_price);
              this.initial.sales_price = Number(this.initial.sales_price);
              for (const item in this.formData) {
                for (const val in this.initial) {
                  if (item === val && item !== 'cost_price' && item !== 'sales_price') {
                    if (JSON.stringify(this.formData[item]) !== JSON.stringify(this.initial[val])) {
                      this.editData.push({
                        field_name: item,
                        before_val: this.initial[val],
                        after_val: this.formData[item],
                      });
                    }
                  }
                }
              }
              if (
                this.$route.query.source === 'sku' &&
                JSON.stringify(this.formData.cost_price) !== JSON.stringify(this.initial.cost_price)
              ) {
                this.editData.push({
                  field_name: 'cost_price',
                  before_val: String(this.initial.cost_price),
                  after_val: String(this.formData.cost_price),
                });
              }
              if (
                this.$route.query.source === 'sku' &&
                JSON.stringify(this.formData.sales_price) !==
                  JSON.stringify(this.initial.sales_price)
              ) {
                this.editData.push({
                  field_name: 'sales_price',
                  before_val: String(this.initial.sales_price),
                  after_val: String(this.formData.sales_price),
                });
              }
              if (
                this.$route.query.source === 'sku' &&
                JSON.stringify(this.formData.product_name) !==
                  JSON.stringify(this.initial.product_name)
              ) {
                this.editData.push({
                  field_name: 'product_code',
                  before_val: this.initial.product_code,
                  after_val: this.formatCode(this.formData.product_name, this.options.product_name),
                });
              }
              console.log('编辑提交', this.editData);
              if (this.editData.length < 1) {
                this.$message.error('您未修改任何数据');
                return false;
              }
              const req = {
                obj_name: this.$route.query.source === 'spu' ? 't_product' : 't_product_sku',
                data_id: this.$route.query.id,
                content: this.editData,
                data_confirm: 0,
              };
              EditMainDataDetail(req)
                .then(res => {
                  if (res.code === '0') {
                    this.$message.success('编辑成功');
                    this.$route.query.type = 'preview';
                    this.$store.state.user.breadcrumb = this.$store.state.user.breadcrumb.slice(
                      0,
                      1,
                    );
                    this.editData = [];
                    this.init();
                  }
                  if (res.code === '1') {
                    res.conflict_list.slice(0, 10).forEach(item => {
                      this.repeatData.push(JSON.parse(item));
                    });
                    this.repeatVisible = true;
                    this.detailEdit = [];
                  }
                })
                .catch(err => {
                  this.$message.error(err.message);
                });
            }
          } else {
            return false;
          }
        });
      },
      2000,
      {
        leading: true,
        trailing: false,
      },
    ),
    handleBack() {
      if (this.$route.query.form) {
        this.$route.query.type = 'preview';
        this.init();
      } else {
        this.$router.push({
          name: 'drug-list',
          query: { type: this.$route.query.source },
        });
      }
    },
    productCodeRemoteMethod(query) {
      if (query !== '') {
        const req = {
          main_data: 't_product',
          fields: ['product_name', 'product_code'],
          conditions: [
            {
              field_name: 'product_name',
              type: 1,
              keywords: [query],
            },
          ],
        };
        SearchMainData(req)
          .then(res => {
            console.log(res);
            const dep = res.data.map(item => JSON.parse(item));
            this.options.product_name = dep.map(item => {
              return { code: item.product_code, label: item.product_name };
            });
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
