export default [
  {
    title: '基础信息',
    itemList: [
      {
        name: 'SKU编码',
        property: 'sku_code',
        editType: 'input',
        edit: false,
        required: true,
      },
      {
        name: 'SKU名称',
        property: 'sku_name',
        editType: 'input',
        edit: false,
        required: true,
      },
      {
        name: 'SKU英文名称',
        property: 'sku_eng_name',
        editType: 'input',
        edit: false,
        required: false,
      },
      {
        name: '所属产品名称',
        property: 'product_name',
        editType: 'lenovo',
        edit: false,
        required: true,
        showType: 'link',
      },
    ],
  },
  {
    title: '规格属性',
    itemList: [
      {
        name: '剂型分类',
        property: 'administration',
        editType: 'select',
        edit: true,
        required: false,
      },
      {
        name: '剂型',
        property: 'prepared_form',
        editType: 'select',
        edit: true,
        required: false,
      },
      {
        name: '单位',
        property: 'unit',
        editType: 'select',
        edit: true,
        required: false,
      },
      {
        name: '大中包装规格',
        property: 'big_package',
        editType: 'input',
        edit: true,
        required: false,
      },
      {
        name: '包装规格',
        property: 'package',
        editType: 'input',
        edit: true,
        required: false,
      },
      {
        name: '规格',
        property: 'specification',
        editType: 'input',
        edit: true,
        required: false,
      },
    ],
  },
  {
    title: '市场信息',
    itemList: [
      {
        name: '成本价',
        property: 'cost_price',
        editType: 'number',
        edit: true,
        required: false,
      },
      {
        name: '售价',
        property: 'sales_price',
        editType: 'number',
        edit: true,
        required: false,
      },
      {
        name: '销售状态',
        property: 'sales_status',
        editType: 'select',
        edit: true,
        required: true,
      },
      {
        name: '是否医保',
        property: 'is_medical_insurance',
        editType: 'select',
        edit: true,
        required: false,
      },
      {
        name: '医保相关信息',
        property: 'medical_insurance_info',
        editType: 'input',
        edit: true,
        required: false,
      },
      {
        name: '上市批准文号/注册证号',
        property: 'approval_number',
        editType: 'input',
        edit: true,
        required: false,
      },
      {
        name: '药品本位码',
        property: 'standard code',
        editType: 'input',
        edit: true,
        required: false,
      },
    ],
  },
  // {
  //     title:'业务信息',
  //     itemList:[

  //     ]
  // }
  {
    title: '系统字段',
    itemList: [
      {
        name: '创建人',
        property: 'create_user',
        editType: '',
        edit: false,
      },
      {
        name: '创建时间',
        property: 'create_time',
        editType: '',
        edit: false,
      },
      {
        name: '更新人',
        property: 'update_user',
        editType: '',
        edit: false,
      },
      {
        name: '更新时间',
        property: 'update_time',
        editType: '',
        edit: false,
      },
      {
        name: '版本',
        property: 'record_version',
        editType: '',
        edit: false,
      },
    ],
  },
  {
    title: '清洗备注',
    itemList: [
      {
        name: '数据来源',
        property: 'source',
        editType: 'select',
        edit: false,
        required: false,
      },
      {
        name: '备注评论',
        property: 'comment',
        editType: 'input',
        edit: true,
        required: false,
      },
      {
        name: '是否存疑',
        property: 'in_doubt',
        editType: 'select',
        edit: false,
        required: false,
      },
      {
        name: '存疑原因',
        property: 'doubt_reason',
        editType: 'input',
        edit: false,
        required: false,
      },
      {
        name: '质量负责人',
        property: 'qa_persons',
        editType: 'input',
        edit: false,
        required: false,
      },
    ],
  },
];
