export default [
  {
    title: '基础信息',
    itemList: [
      {
        name: '所属产品领域编码',
        property: 'therapeutic_area_code',
        editType: '',
        edit: false,
        // required: true,
        disable: true,
      },
      {
        name: '所属产品领域名称',
        property: 'therapeutic_area_name',
        editType: 'select',
        edit: false,
        required: true,
      },
      {
        name: '产品线/产品类型编码',
        property: 'productline_code',
        editType: '',
        edit: false,
        // required: true,
        disable: true,
      },
      {
        name: '产品线/产品类型名称',
        property: 'productline_name',
        editType: 'select',
        edit: false,
        required: true,
      },
      {
        name: '产品编码',
        property: 'product_code',
        editType: 'input',
        edit: false,
        required: true,
      },
      {
        name: '产品名称',
        property: 'product_name',
        editType: 'input',
        edit: false,
        required: true,
      },
      {
        name: '产品英文名称',
        property: 'product_eng_name',
        editType: 'input',
        edit: false,
        required: false,
      },
      {
        name: '商品名',
        property: 'trade_name',
        editType: 'input',
        edit: true,
        required: false,
      },
      {
        name: '商品英文名',
        property: 'trade_eng_name',
        editType: 'input',
        edit: true,
        required: false,
      },
    ],
  },
  {
    title: '临床信息',
    itemList: [
      {
        name: '药品通用名',
        property: 'common_name',
        editType: 'input',
        edit: true,
        required: false,
      },
      {
        name: '药品英文通用名',
        property: 'common_eng_name',
        editType: 'input',
        edit: true,
        required: false,
      },
      {
        name: '药品类型',
        property: 'category',
        editType: 'select',
        edit: true,
        required: false,
      },
      {
        name: '成分',
        property: 'ingredients',
        editType: 'input',
        edit: true,
        required: false,
      },
      {
        name: '是否处方药',
        property: 'is_RX',
        editType: 'select',
        edit: true,
        required: false,
      },
    ],
  },
  {
    title: '系统字段',
    itemList: [
      // {
      //     name: '是否已删除',
      //     property: 'is_deleted',
      //     editType: 'select',
      //     edit: false,
      // },
      {
        name: '创建人',
        property: 'create_user',
        editType: '',
        edit: false,
      },
      {
        name: '创建时间',
        property: 'create_time',
        editType: '',
        edit: false,
      },
      {
        name: '更新人',
        property: 'update_user',
        editType: '',
        edit: false,
      },
      {
        name: '更新时间',
        property: 'update_time',
        editType: '',
        edit: false,
      },
      {
        name: '版本',
        property: 'record_version',
        editType: '',
        edit: false,
      },
    ],
  },
  {
    title: '清洗备注',
    itemList: [
      {
        name: '数据来源',
        property: 'source',
        editType: 'select',
        edit: false,
        required: false,
      },
      {
        name: '备注评论',
        property: 'comment',
        editType: 'input',
        edit: true,
        required: false,
      },
      {
        name: '是否存疑',
        property: 'in_doubt',
        editType: 'select',
        edit: false,
        required: false,
      },
      {
        name: '存疑原因',
        property: 'doubt_reason',
        editType: 'input',
        edit: false,
        required: false,
      },
      {
        name: '质量负责人',
        property: 'qa_persons',
        editType: 'input',
        edit: false,
        required: false,
      },
    ],
  },
];
